import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { H3, H4, Heading } from '../../components';

import css from './SectionCategoriesMaybe.module.css';
import { HOSPITAL_DOCTOR } from '../../util/types';
import { mapFieldsOptionsToLabel } from '../../util/data';

const SectionCategoriesMaybe = props => {
  const { publicData, heading, listingConfig } = props;
  const { category, sub_category = [], speciality = [], sub_speciality = [] } = publicData;
  if (!category) return null;
  const categoryEnumOptions =
    listingConfig?.listingFields?.find(lc => lc.key === 'category')?.enumOptions || [];

  const subCategoryEnumOptions =
    listingConfig?.listingFields?.find(lc => lc.key === 'sub_category')?.enumOptions || [];
  const specialityEnumOptions =
    listingConfig?.listingFields?.find(lc => lc.key === 'speciality')?.enumOptions || [];
  const subSpecialityEnumOptions =
    listingConfig?.listingFields?.find(lc => lc.key === 'sub_speciality')?.enumOptions || [];

  // Map the speciality array to the corresponding labels
  // const specialityLabels = speciality.map(specialityItem =>
  //   mapFieldsOptionsToLabel(specialityEnumOptions, specialityItem)
  // );
  const formatedSpecialityLabel = specialityEnumOptions?.find(data => data.option === speciality)
    ?.label;
  const subCategoryMaybe =
    sub_category.length > 0 ? (
      <div className={css.subCategoryText}>
        <H4 className={css.subTitle}>
          <FormattedMessage id="ListingPage.subCategoryTitle" />
        </H4>
        <span className={css.subListText}>
          <ul className={css.subListTextList}>
            {sub_category.map(s => (
              <li key={s}>{mapFieldsOptionsToLabel(subCategoryEnumOptions, s)}</li>
            ))}
          </ul>
        </span>
      </div>
    ) : null;

  // Map speciality labels
  const specialityMaybe = !!formatedSpecialityLabel ? (
    <div className={css.subCategoryText}>
      <H4 className={css.subTitle}>
        <FormattedMessage id="ListingPage.specialityTitle" />
      </H4>
      <ul className={css.subListTextList}>
        <li>{formatedSpecialityLabel}</li>
        {/* {specialityLabels.map((label, index) => (
          <li key={index}>{label}</li>
        ))} */}
      </ul>
    </div>
  ) : null;

  const subSpecialityMaybe =
    sub_speciality.length > 0 ? (
      <div className={css.subCategoryText}>
        <H4 className={css.subTitle}>
          <FormattedMessage id="ListingPage.subSpecialityTitle" />
        </H4>
        <ul className={css.subListTextList}>
          {sub_speciality.map(s => (
            <li key={s}>{mapFieldsOptionsToLabel(subSpecialityEnumOptions, s)}</li>
          ))}
        </ul>
      </div>
    ) : null;

  const categoryLabel = mapFieldsOptionsToLabel(categoryEnumOptions, category);

  const doctorDataMaybe =
    category === HOSPITAL_DOCTOR ? (
      <div className={css.categoryWrapper}>
        {subCategoryMaybe}
        {specialityMaybe}
        {subSpecialityMaybe}
      </div>
    ) : null;

  return (
    <div>
      <H3 className={css.titleTextBorder}>{heading}</H3>
      <div className={css.categoryWrapper}>
        <span className={css.categoryText}>{categoryLabel}</span>
      </div>

      {doctorDataMaybe}
    </div>
  );
};

export default SectionCategoriesMaybe;
