import React from 'react';
import { H3, H4, H5 } from '../../components';
import css from './SectionInformaitionMaybe.module.css';
import IconCollection from '../../components/IconCollection/IconCollection';
import appSettings from '../../config/settings';

const SectionInformationMaybe = props => {
  const { heading, publicData: { location, prices }, price, intl, config } = props;

  const { address } = location || {};
  const currencyConfig = appSettings.getCurrencyFormatting(config.currency);

  const locationMaybe = !!address ? <H5>{address}</H5> : null;
  const priceMaybe = !!price ? <H5>{price}</H5> : null;

  const { min, max } = prices || {};
  const maxPrice = !!max && max.amount ? max.amount / 100 : null;
  const minPrice = !!min && min.amount ? min.amount / 100 : null;

  const pricingMaybe = minPrice && maxPrice ? (
    <span>{`${intl.formatNumber(minPrice, currencyConfig)} - ${intl.formatNumber(maxPrice, currencyConfig)}`}</span>
) : null;

  return (
    <div>
      <H3 className={css.titleTextBorder}>{heading}</H3>
      <div className={css.informationWrapper}>
        <div className={css.contentWrapper}>
          <div>
            <IconCollection name="LOCATION_ICON" />
          </div>
          <div>
            <h6>Location</h6>
            <p>{address}</p>
          </div>
        </div>
        <div className={css.contentWrapper}>
          <div>
            <IconCollection name="CLOCK_ICON" />
          </div>
          <div>
            <h6>Fee</h6>
            <p>{price}</p>
            <p>{pricingMaybe}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionInformationMaybe;
